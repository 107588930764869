import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import './common-modal.component.scss'

class CommonModalComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <div className="healthcare-professional-modal">
        <Modal
          className="content-modal healthcare-professional-modal"
          show={this.props.isShowModal}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
        </Modal>
      </div>
    )
  }
}

const { bool, func } = PropTypes

CommonModalComponent.propTypes = {
  isShowModal: bool.isRequired,
  onClose: func,
  title: PropTypes.string,
  children: PropTypes.node
}

export default CommonModalComponent
