import React from 'react'
import red from '@material-ui/core/colors/red'
import RadioGroup from '@material-ui/core/RadioGroup'
import { ValidatorComponent } from './validator-core'

const red300 = red['500']

const style = {
  left: 0,
  fontSize: '13px',
  color: red300,
  position: 'absolute',
  marginTop: '4px',
  fontFamily: 'Open Sans',
  fontWeight: 500
}

class RadioValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const { errorMessages, validators, requiredError, value, ...rest } = this.props
    return (
      <div>
        <RadioGroup {...rest} onBlur={e => this.handleBlur(e)}>{this.props.children}</RadioGroup>

        {this.errorText()}
      </div>
    )
  }

  handleBlur(event) {
    this.validate(event.target.value)
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return <div style={style}>{this.getErrorMessage()}</div>
  }
}

export default RadioValidator
