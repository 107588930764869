import React from 'react'
import { ValidatorComponent } from './validator-core'
import red from '@material-ui/core/colors/red'

import NativeSelect from '@material-ui/core/NativeSelect'
import FormHelperText from '@material-ui/core/FormHelperText'

const red300 = red['500']

const errorStyle = {
  left: 0,
  fontSize: '14px',
  color: red300,
  marginTop: '7px',
  fontFamily: 'Open Sans',
  fontWeight: 500,
  lineHeight: 1,
  display: 'block'
}

export default class SelectValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      className,
      ...rest
    } = this.props
    const { isValid } = this.state
    return (
      <div className={className}>
        <NativeSelect {...rest} error={!isValid || error} onBlur={e => this.handleBlur(e)} />
        <div>
          <FormHelperText style={errorStyle}>{this.errorText()}</FormHelperText>
        </div>
      </div>
    )
  }

  handleBlur(event) {
    this.validate(event.target.value)
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return this.getErrorMessage()
  }
}
