import React from 'react'
import PropTypes from 'prop-types'
import red from '@material-ui/core/colors/red'
import Checkbox from '@material-ui/core/Checkbox'
import { ValidatorComponent } from './validator-core'

const red300 = red['500']

const style = {
  left: 0,
  fontSize: '14px',
  color: red300,
  position: 'absolute',
  marginTop: '-55px',
  fontFamily: 'Open Sans',
  fontWeight: 500
}

class CheckboxValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const { errorMessages, validators, requiredError, value, ...rest } = this.props

    return (
      <div className="checkbox-container">
        <Checkbox
          {...rest}
          ref={r => {
            this.input = r
          }}
          onClick={e => this.handleClick(e)}
        />
        {this.errorText()}
      </div>
    )
  }

  handleClick(event) {
    this.validate(event.target.checked)
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return (
      <div className="checkbox-error-message" style={style}>
        {this.getErrorMessage()}
      </div>
    )
  }
}

const { func } = PropTypes

CheckboxValidator.propTypes = { errorText: func }

export default CheckboxValidator
