import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Layout from "@components/layout/Layout";
import CommonModal from "@components/common-modal/common-modal.component";
import LeavingSiteModal from "@components/leaving-site-modal/leaving-site-modal.component";
import ReadNext from "@components/read-next-button/read-next-button";
import { Radio } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  TextValidator,
  SelectValidator,
  CheckboxValidator,
  ValidatorForm,
  RadioValidator,
} from "@components/validators";

import { liveRampEventTracking, win } from "@src/app-utils";
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { trackGa } from "@components/GoogleTracking";
import config from "@utils/config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./connect-with-us.scss";

const specialityList = [
  { id: "Sleep Specialist", name: "Sleep Specialist" },
  { id: "Neurologist", name: "Neurologist" },
  { id: "Pulmonologist", name: "Pulmonologist" },
  { id: "Psychiatrist", name: "Psychiatrist" },
  { id: "Primary Care", name: "Primary Care" },
  { id: "Other", name: "Other" },
];

const patientCountList = [
  { id: 0, name: "" },
  { id: 25, name: "1-25" },
  { id: 50, name: "26-50" },
  { id: 99, name: "51-99" },
  { id: 100, name: "100+" },
];

const ReCAPTCHAKey = `${config.REACT_APP_GOOGLE_RECAPTCHA}`;

class ConnectWithUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.cleanState();
    this.fields = {};
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.handleCloseSucess = this.handleCloseSucess.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.optionalInfo = this.optionalInfo.bind(this);
  }

  handleWindowResize() {
    win && this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    win && this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.handleWindowResize);
  }

  cleanState() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      zip: "",
      npi: "",
      specialty: "",
      optInEmail: false,
      submitting: false,
      showSuccessModal: this.props.location.search.includes("ed=1&success=true") ? true : false,
      showErrorModal: false,
      errors: null,
      focus: false,
      showLeavingSiteModal: false,
      pediatricSpecialist: "",
      href: "",
      width: "",
      isHealthcareProfessional: "",
      patientCount: "",
      narcolepsyPatients: "",
    };
  }

  handleInputChange(label, value) {
    if (label == 'npi') {
      value = value.replace(/[^0-9]/g, '');
    }
    this.setState({
      [label]: value,
    });
  }

  handleCloseSucess() {
    this.setState({
      showSuccessModal: false,
    });
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false,
    });
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    });
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    });
  }

  openLink(link) {
    this.setState(() => {
      return { href: link };
    }, this.handleShowModalClicked());
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === "string") {
        return <p className="errors">{errors}</p>;
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>;
            })}
          </ul>
        );
      }
    }
  }

  optionalInfo(count) {
    if (count === "1-25") {
      trackGa(
        `stay connected, click, currently manage patients - ${count} - stay connected`
      );
      trackGa("stay connected, optional, patients25");
    } else if (count === "26-50") {
      trackGa(
        `stay connected, click, currently manage patients - ${count} - stay connected`
      );
      trackGa("stay connected, optional, patients50");
    } else if (count === "51-99") {
      trackGa(
        `stay connected, click, currently manage patients - ${count} - stay connected`
      );
      trackGa("stay connected, optional, patients99");
    } else if (count === "100+") {
      trackGa(
        `stay connected, click, currently manage patients - ${count} - stay connected`
      );
      trackGa("stay connected, optional, patients100+");
    }
  }

  async onSubmitForm(event) {
    event.preventDefault();

    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const {
      firstName,
      lastName,
      email,
      zip,
      npi,
      optInEmail,
      specialty,
      patientCount,
      narcolepsyPatients,
      isHealthcareProfessional,
    } = this.state;

    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    const recaptcha = await executeRecaptcha("formSubmit");

    const postData = {
      firstName,
      lastName,
      email,
      zipCode: zip,
      npi: npi,
      optInEmail: optInEmail ? "Yes" : "No",
      specialty: specialty,
      narcolepsyPatients,
      hcpProfessional: isHealthcareProfessional ? "Yes" : "No",
      recaptcha: recaptcha,
      dataSource: "NARCOLEPSYLINK-STAY-CONNECTED-2",
    };

    if (patientCount !== "") {
      postData.patientCount = patientCount;
    }

    const API_URL =
      /^(www\.)?narcolepsylink.com|us19\.prod\.acquia-sites\.com$/.test(
        window.location.hostname
      )
        ? "https://jazz-api.klickcloud.net/api/register"
        : "https://jazz-api-stage.klickcloud.net/api/register";

    // console.log(postData)
    fetch(API_URL, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data");
        }
        return res.json();
      })
      .then((body) => {
        if (body.success) {
          this.setState(
            {
              ...this.cleanState(),
              showSuccessModal: true,
            },
            () => {
              trackGa(
                "stay connected, click, submit information - stay connected"
              );
              this.optionalInfo(postData.patientCount);
              trackGa(
                `stay connected, click, currenly manage patients - ${narcolepsyPatients} - stay connected`
              );
              trackGa("stay connected, open, sign up confirmation overlay");
              liveRampEventTracking("connect-with-us-submit");
            }
          );
        } else {
          console.error(body);
          this.setState({
            submitting: false,
            showErrorModal: true,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          submitting: false,
          showErrorModal: true,
        });
      });
  }
  render() {
    const readNext = {
      readNextContent: "Sleep organizations",
      goTo: "/stay-connected/narcolepsy-information-links/",
      tracking: "stay connected, click,	key organizations - stay connected",
    };

    return (
      <Layout addedClass="page-connect-with-us" location={this.props.location}>
        <div className="join-narcolepsy-link">
          <div className="primary-container">
            <div className="page-container">
              <h1 className="main-page-header">Connect With Us!</h1>
              <h2 className="section-header">Join NarcolepsyLink</h2>
              <p className="form-comment">
                Your contact information helps us keep you informed about
                narcolepsy.
              </p>
            </div>
            <div className="page-container">
              <p className="form-header">Enter your information</p>
              <ValidatorForm
                onSubmit={this.onSubmitForm}
                instantValidate={false}
              >
                <div className="form-row">
                  <TextValidator
                    name="firstName"
                    className="input-normal"
                    label="First Name*"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.handleInputChange("firstName", e.target.value)
                    }
                    validators={["required", "isName"]}
                    errorMessages={[
                      "Please fill out this field.",
                      "No numbers or special characters in this field.",
                    ]}
                  />
                  <TextValidator
                    name="lastName"
                    className="input-normal"
                    label="Last Name*"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.handleInputChange("lastName", e.target.value)
                    }
                    validators={["required", "isName"]}
                    errorMessages={[
                      "Please fill out this field.",
                      "No numbers or special characters in this field.",
                    ]}
                  />
                </div>
                <div className="form-row">
                  <TextValidator
                    label="Email Address*"
                    className="input-large"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={(e) =>
                      this.handleInputChange("email", e.target.value)
                    }
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Please fill out this field.",
                      "Your email address does not have a valid domain name.",
                    ]}
                  />
                  <TextValidator
                    className="input-normal"
                    name="zip"
                    label="Zip Code*"
                    value={this.state.zip}
                    onChange={(e) =>
                      this.handleInputChange("zip", e.target.value)
                    }
                    validators={["required", "isZip"]}
                    errorMessages={[
                      "Please fill out this field.",
                      "ZIP code is not valid.",
                    ]}
                  />
                </div>
                <div className="form-row">
                  <SelectValidator
                    name="speciality"
                    className="input-normal"
                    value={this.state.specialty}
                    onChange={(e) =>
                      this.handleInputChange("specialty", e.target.value)
                    }
                    required
                    label="Speciality"
                    validators={["required"]}
                    errorMessages={["Please select an option."]}
                  >
                    <option value="" disabled hidden selected>
                      Speciality*
                    </option>
                    {specialityList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </SelectValidator>
                  <TextValidator
                    className="input-normal"
                    name="npi"
                    label="NPI Number*"
                    value={this.state.npi}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) =>
                      this.handleInputChange("npi", e.target.value)
                    }
                    validators={["required", "isNPI", "isNumbers"]}
                    errorMessages={[
                      "Please enter your NPI Number.",
                      "NPI Number is not valid.",
                      "NPI Number is not valid.",
                    ]}
                  />
                </div>
                <FormControl>
                  <label className="item-comment-label">
                    <b>
                      Do you currently manage patients with narcolepsy in your
                      practice?*
                    </b>
                  </label>
                  <RadioValidator
                    name="narcolepsyPatients"
                    value={this.state.narcolepsyPatients}
                    validators={["required"]}
                    errorMessages={["Please select an option."]}
                    onClick={(e) =>
                      this.handleInputChange(
                        "narcolepsyPatients",
                        e.target.value
                      )
                    }
                  >
                    <FormControlLabel
                      className="radio-input"
                      value={"True"}
                      control={
                        <Radio
                          color="default"
                          checked={this.state.narcolepsyPatients === "True"}
                        />
                      }
                      label={<label>Yes</label>}
                    />
                    <FormControlLabel
                      className="radio-input"
                      value={"False"}
                      control={
                        <Radio
                          color="default"
                          checked={this.state.narcolepsyPatients === "False"}
                        />
                      }
                      label={<label>No</label>}
                    />
                  </RadioValidator>
                </FormControl>
                <FormControl>
                  <label className="item-comment-label">
                    <b>
                      If yes, how many patients? (<i>optional</i>)
                    </b>
                  </label>
                  <div className="select-comment-wrapper">
                    <SelectValidator
                      name="patientCount"
                      className="input-small mar-right"
                      value={this.state.patientCount}
                      onChange={(e) =>
                        this.handleInputChange("patientCount", e.target.value)
                      }
                      required
                    >
                      {patientCountList.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </SelectValidator>
                    <div className="after-comment">patient(s)</div>
                  </div>
                </FormControl>
                <div className="form-row checkbox-group">
                  <FormControlLabel
                    className="error-position-one"
                    control={
                      <CheckboxValidator
                        validators={["isTrue"]}
                        errorMessages={[
                          "Please check this box if you want to proceed.",
                        ]}
                        value={this.state.isHealthcareProfessional}
                        name="isHealthcareProfessional"
                        checked={this.state.isHealthcareProfessional}
                        onChange={(e) =>
                          this.handleInputChange(
                            "isHealthcareProfessional",
                            e.target.checked
                          )
                        }
                        color="primary"
                      />
                    }
                    label="I confirm I am a healthcare professional in the US.*"
                  />
                  <FormControlLabel
                    className="error-position-two"
                    control={
                      <CheckboxValidator
                        name="optInEmail"
                        validators={["isTrue"]}
                        errorMessages={[
                          "Please check this box if you want to proceed.",
                        ]}
                        value={this.state.optInEmail}
                        checked={this.state.optInEmail}
                        onChange={(e) =>
                          this.handleInputChange("optInEmail", e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="By checking this box, you confirm that you are 18 years of age or older and a resident of the US. You are indicating that you would like to receive information from Jazz Pharmaceuticals about educational programs, products, and services. You can opt out at any time.*"
                  />
                  <p className="privacy-comment">
                    We respect your privacy. We will not share your information
                    with any third parties or outside mailing lists. Read our{" "}
                    <b>
                      <a
                        className="inline-link"
                        onClick={() => {
                          trackGa(
                            "global - footer peds, click, privacy statement - footer peds"
                          );
                          this.openLink(
                            "https://www.jazzpharma.com/privacy-statement/"
                          );
                        }}
                      >
                        Privacy Statement.
                      </a>
                    </b>
                  </p>
                </div>
                <div className="form-row">
                  <button
                    type="submit"
                    className="btn btn-primary submit-button"
                    text="SIGN UP"
                  >
                    {this.state.submitting && (
                      <FontAwesomeIcon
                        className="loading"
                        icon={faSpinner}
                        spin
                      />
                    )}
                    SIGN UP&nbsp;{" "}
                    <FontAwesomeIcon
                      className="arrow-right"
                      icon="angle-right"
                    />
                  </button>
                </div>
              </ValidatorForm>
            </div>
            <div className="margin-top-30" />
            <ReadNext readNext={readNext} />
          </div>
          <CommonModal isShowModal={this.state.showSuccessModal}>
            <div className="body-content">
              <h2 className="title">
                Thank you for joining <br /> NarcolepsyLink
              </h2>
              <p className="content">
                We look forward to providing <br className="mobile-only" /> you
                with <br className="desktop-only" /> additional{" "}
                <br className="mobile-only" /> information about managing <br />{" "}
                patients with narcolepsy.
              </p>
              <div className="action">
                <button
                  className="button btn-primary"
                  onClick={this.handleCloseSucess}
                >
                  Ok
                </button>
              </div>
            </div>
          </CommonModal>
          <CommonModal isShowModal={this.state.showErrorModal}>
            <div className="body-content">
              <h2 className="title">Error</h2>
              <p className="content">
                A server error occurred while submitting your request.
              </p>
              {this.getErrorsFormatted(this.state.errors)}
              <div className="action">
                <button
                  className="button btn-primary"
                  onClick={this.handleCloseError}
                >
                  Ok
                </button>
              </div>
            </div>
          </CommonModal>
          <LeavingSiteModal
            isShowModal={this.state.showLeavingSiteModal}
            onClose={this.handleHideModal}
            href={this.state.href}
            location={this.props.location}
          />
        </div>
      </Layout>
    );
  }
}

// export default ConnectWithUs;

// Hacky solution using HOC, this should be refactored to useGoogleReCaptcha hook once the component is refactored to a functional component
const WithGoogleRecaptcha = withGoogleReCaptcha(ConnectWithUs);

class ConnectWithUsWithReCaptcha extends React.Component {
	render() {
		return (
			<GoogleReCaptchaProvider
				reCaptchaKey={ReCAPTCHAKey}
			>
				<WithGoogleRecaptcha {...this.props} />
			</GoogleReCaptchaProvider>
		);
	}
}
export default ConnectWithUsWithReCaptcha;
