import { ValidatorComponent as ValidatorComponentLib, ValidatorForm as ValidatorFormLib } from './validator-core'

import SelectValidatorLib from './SelectValidator'
import TextValidatorLib from './TextValidator'
import CheckboxValidatorLib from './CheckboxValidator'
import RadioVAlidatorLib from './RadioValidator'

export const SelectValidator = SelectValidatorLib
export const TextValidator = TextValidatorLib
export const ValidatorComponent = ValidatorComponentLib
export const ValidatorForm = ValidatorFormLib
export const CheckboxValidator = CheckboxValidatorLib
export const RadioValidator = RadioVAlidatorLib
