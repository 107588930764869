import React from 'react'
import TextField from '@material-ui/core/TextField'
import { ValidatorComponent } from './validator-core'

export default class TextValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      ...rest
    } = this.props
    const { isValid } = this.state
    return (
      <TextField
        onBlur={e => this.handleBlur(e)}
        {...rest}
        error={!isValid || error}
        helperText={(!isValid && this.getErrorMessage()) || helperText}
      />
    )
  }
  handleBlur(event) {
    this.validate(event.target.value)
  }
}
