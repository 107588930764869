import { emailRegex } from '../../../assets/constants/validation'

const isExisty = function(value) {
  return value !== null && value !== undefined
}

const isEmpty = function(value) {
  if (value instanceof Array) {
    return value.length === 0
  }
  return value === '' || !isExisty(value)
}

const isEmptyTrimed = function(value) {
  if (typeof value === 'string') {
    return value.trim() === ''
  }
  return true
}

const isTrue = function(value){
  return value ? value : false
}

const isValidDate = function(value) {
  let varDate = new Date(value)
  let today = new Date()
  let year = varDate.getFullYear()
  today.setHours(0, 0, 0, 0)
  if (varDate >= today) return true
  else if (varDate > year + 100) return false
}

export const validations = {
  matchRegexp: (value, regexp) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp)
    return isEmpty(value) || validationRegexp.test(value)
  },

  // eslint-disable-next-line

  isEmail: value =>
    validations.matchRegexp(
      value,
      // eslint-disable-next-line
      emailRegex
    ),

  isEmpty: value => isEmpty(value),

  required: value => !isEmpty(value),

  isTrue: value => isTrue(value),

  trim: value => !isEmptyTrimed(value),

  isNumber: value => validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

  isFloat: value => validations.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),

  isPositive: value => {
    if (isExisty(value)) {
      return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0
    }
    return true
  },

  isPositiveInt: value => {
    if (isExisty(value)) {
      return validations.isNumber(value) && value >= 0
    }
    return true
  },

  maxNumber: (value, max) => isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),

  minNumber: (value, min) => isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),

  maxFloat: (value, max) => isEmpty(value) || parseFloat(value) <= parseFloat(max),

  minFloat: (value, min) => isEmpty(value) || parseFloat(value) >= parseFloat(min),

  isString: value => !isEmpty(value) || typeof value === 'string' || value instanceof String,
  minStringLength: (value, length) => validations.isString(value) && value.length >= length,
  maxStringLength: (value, length) => validations.isString(value) && value.length <= length,

  isName: value => validations.matchRegexp(value, /^[a-zA-Z\s]+$/),

  isPhoneNumber: value =>
    validations.matchRegexp(
      value,
      /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/
    ),

  isZip: value => validations.matchRegexp(value, /^\d{5}(?:[-\s]\d{4})?$/),

  isNPI: value => validations.matchRegexp(value, /^\d{10}$/),

  isAlphanumeric: value => validations.matchRegexp(value, /^(?![0-9 ]*$)[a-zA-Z0-9 ]+$/),

  isNumbers: value => validations.matchRegexp(value, /^[0-9]*[1-9][0-9]*$/),

  isDea: value => validations.matchRegexp(value, /^[ABFGMPRabfgmpr]{1}[A-Za-z]{1}[0-9]{7}$/),

  isDate: value => validations.matchRegexp(value, /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/),

  isValidDate: value => isValidDate(value),

  isWebsite: value =>
    validations.matchRegexp(value, /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/)
}
